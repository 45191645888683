@import url('https://fonts.googleapis.com/css2?family=Germania+One&family=Gotu&family=Karma&display=swap');

#main-spell-details {
    top: 190px;
}

.spell-details-mobile{

    width: 284px;
    height: fit-content;
    z-index: 1200;
    
    display: none;   /* set this to grid to make visible */
    grid-template-columns: 132px 96px 60px; 
    grid-template-rows: 44px 30px 30px 30px 42px auto;
}

.spell-detail-title-mobile {
    grid-row: 1;
    grid-column: 1/4;
    border: none;
    text-align: center;
    font-family: 'Milonga';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 46px;
}

.spell-info-mobile {
    border: none;
    text-align: left;
    padding-left: 10px; 
    font-family: 'Milonga';
    line-height: 32px;
    font-size: 16px;
}

.spell-info-value-mobile {
    font-family: 'Germania One';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
}

.spell-detail-casting-mobile {
    grid-row: 2;
    grid-column: 1/2;
    font-size: 9pt;
}

.spell-detail-duration-mobile {
    grid-row: 4;
    grid-column: 1/3;
    font-size: 9pt;
    
}

.spell-detail-range-mobile {
    grid-row: 3;
    grid-column: 1/3;
    font-size: 9pt;
}

.spell-detail-save-mobile {
    grid-row: 2;
    grid-column: 2/3;
    font-size: 9pt;
    
}

.spell-detail-aoe-mobile {
    grid-row: 5;
    grid-column: 1/3;
    font-size: 9pt;
    line-height: 12pt;
    margin-top: 8pt;
}

.spell-value-aoe-mobile {
    font-size: 12pt;
}

.spell-detail-summary-mobile {
    grid-row: 2 / 5 ;
    grid-column: 3;
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: start;
    text-align: left;
    padding-left: 0px;
    padding-top: 4px;
}

.spell-detail-effect-mobile {
    grid-row: 5;
    grid-column: 3;
    margin-left:0px;
    font-size: 14px;
    text-align: left;
}

.spell-summary-lg {
    font-family: 'Gill Sans MT';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 23px;
}

.spell-summary-sm {
    font-family: 'Droid Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;    
}

.spell-detail-desc-mobile {
    grid-row: 6;
    grid-column: 1/4;
    text-align: left;
    padding: 6px;
    padding-top: 10px;
    font-family: 'Karma';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.04em;
    white-space: pre-line;
}

.spell-detail-desc > pre {
    padding: 0px; margin: 0px;
}

.spell-detail-desc-mobile > pre.table {
    font-size: 14px;
}