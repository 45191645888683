@import url('https://fonts.googleapis.com/css2?family=Germania+One&family=Gotu&family=Karma&display=swap');

#main-spell-details {
    top: 190px;
}

.spell-details {
    position: absolute;
    top: 110px;
    left: calc(720px/2 - 540px/2 + 30px);
    width: 550px;
    height: fit-content;
    background: #fffef9;
    z-index: 1200;
    border: 2px solid black;
    display: none;   /* set this to grid to make visible */
    grid-template-columns: 232px 232px 86px; 
    grid-template-rows: 46px 32px 32px 32px auto;
}

.spell-detail-title {
    grid-row: 1;
    grid-column: 1/3;
    border: none;
    text-align: center;
    font-family: 'Milonga';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 46px;
}

.spell-info {
    border: none;
    text-align: left;
    padding-left: 10px; 
    font-family: 'Milonga';
    line-height: 32px;
    font-size: 16px;
}

.spell-info-value{
    font-family: 'Germania One';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
}

.spell-detail-casting {
    grid-row: 2;
    grid-column: 1/2;
}

.spell-detail-duration {
    grid-row: 3;
    grid-column: 2/3;
}

.spell-detail-range {
    grid-row: 3;
    grid-column: 1/2;
}

.spell-detail-save {
    grid-row: 2;
    grid-column: 2/3;
}

.spell-detail-aoe {
    grid-row: 4;
    grid-column: 1/3;
}

.spell-detail-summary {
    grid-row: 1 / 4 ;
    grid-column: 3;
    border: none;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    text-align: center;
    padding-left: 4px;
}

.close-spell-detail {
    margin-top: 2px;
    width: 90%;
}

.spell-detail-effect {
    grid-row: 4;
    grid-column: 3/4;
    line-height: 16px;
    font-size: 16px;
    text-align: center;
    margin-top: 2px;
}

.spell-summary-lg {
    font-family: 'Gotu';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 23px;
}

.spell-summary-sm {
    font-family: 'Droid Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;    
}

.spell-detail-desc {
    grid-row: 5;
    grid-column: 1/4;
    text-align: left;
    padding: 10px;
    font-family: 'Karma';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.04em;
    white-space: pre-line;
}

[class^="spell-detail-desc"] > pre {
    padding: 0px; margin: 0px;
}

[class^="spell-detail-desc"] > pre.table  {
    font-size: 16px;
    font-family: 'Consolas', 'Roboto Mono', 'Droid Sans Mono', 'Menlo', 'Courier New', Courier, monospace;
}

