.App {
  text-align: center;
}

/*
#main-app {
}
*/

html {
  width: min-content;
}

.App-logo {
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@media print
{    
  .no-print, .no-print *
  {
      display: none !important;
  }
  #main-app {
    margin-left: 18px !important;
    margin-top: -8px !important;
    size: auto !important;
  }
  body {
    background-image: none;
  }
  .dim {
    display: none !important;
  }
  .spell-check-group {
    margin-left: 13px;
    opacity: 50%;
  }

}


/* special scaling for iphone 13 mini */
@media (any-hover: none ) and (max-device-width: 376px) {
  #main-app { transform: scale(4.4) }
}


/* special scaling for Pixel Pro 7 */
@media (any-hover: none ) and (max-device-width: 413px) and (min-device-width: 411px) {
  #main-app { transform: scale(calc(3100/var(--vw))) }
  /* calc(2100/var(--vw)) 5.21*/
}


/* not currently used?? */
@media (any-hover: none) and (max-device-width: 720px) {
  #main-app {
    transform: scale(4.8); /* from 718 to 1436 for 1440 displays. 3.2 is the old value */
    transform-origin: top left;
    margin-left: 20px;
    margin-top: 10px;
  }
}


/* special scaling for iPad Air 6 */
@media (any-hover: none ) and (min-device-width: 765px) and (min-resolution: 2.01dppx) {
  #main-app { transform: scale(calc(var(--orientation)));
    transform-origin: top left;
    margin-left: 20px;
    margin-top: 10px;
  }
}


@media print { 
  #main-app { transform: scale(1) !important;
    transform-origin: top left;
   }
  @page {
  size: auto !important;
  margin-left: 20px !important;
  margin-top: 20px !important; } }