#newsletter {
  width: 900px;
  padding-left: 10px;
}

#newsletter p {
  font-size: 18pt;
  width: 78%;
}

#newsletter h1 {
  font-size: 28pt;
  width: 80%;
}

#newsletter h2 {
  font-size: 24pt;
  width: 80%;
}

#last {
  padding-bottom: 24pt;
}

.newsimage {
  width: 700px;
}