#MobileSpellDetail {
  display: none;
  z-index: 1090;
}  

#MobileSpellDetail .spell-details-mobile {
  display: grid
  }

#MobileSpellDetail .close-spell-detail-mobile {
  display: none
}

#MobileSpellDetail .button-078 {
  display: block
}

#MobileSpellDetail .spell-detail-title-mobile {
  font-size: 16pt;
  color:#ff7426
}