.spell-check-group {
  text-align: right;
  width: 200px;
  height: 15px;
  position: relative;
  left: 59px;
  align-items: end;
  display: flex;
  z-index: 1125;
}

.spell-check-group .checkbox {
  opacity: 1;
  margin:0px;
  padding: 0px;
  border-width: 1px;
  outline-width: 1px;
}

.spell-check-group input {
  width: 14px;

}


/* .checkbox:last-child {
  visibility: visible;
}
*/

.dim {
  opacity: 0.5;
  /* Bootstrap 5 classes for dim style */
  filter: opacity(20%);
}

.Ready {
  accent-color: #89a7ff; /* rgb(0, 92, 0); */
  filter: opacity(95%);
  
}

.Used {
  accent-color: rgb(199, 44, 44); /* rgb(126, 9, 9); */
  filter: opacity(65%);
}
input[type="checkbox"] {
}