#rules-img-mobile {
  width: 278px;
}

.rules-mobile {
  display: none;
  margin-top: 42px;
}

#footer {
  height: 130px;
  min-height:130px;
  font-family:'Courier New', Courier, monospace
}

#navBarMobile .rules-menu li {
  margin-bottom: 0px; 
}

#navBarMobile .rules-menu button {
  padding-top: 2px; 
}

#navBarMobile .rules-menu {
  padding-top: 2px;
  width: 220px;
}

#navBarMobile .settings-menu {
  margin-left: -140px;
  width: 205px;
}
/* CSS FOR RULES WINDOW BUTTON */
.button-078 {
  align-items: center;
  appearance: none;
  background-clip: padding-box;
  background-color: initial;
  background-image: none;
  border-style: none;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  flex-direction: row;
  flex-shrink: 0;
  font-family: Eina01,sans-serif;
  font-size: 16px;
  font-weight: 800;
  justify-content: center;
  line-height: 24px;
  margin: 0;
  /* min-height: 64px; */
  outline: none;
  overflow: visible;
  /* padding: 19px 26px; */
  pointer-events: auto;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  /* width: auto; */
  word-break: keep-all;
  z-index: 0;
}

@media (min-width: 768px) {
  .button-078 {
    padding: 19px 32px;
  }
}

.button-078:before,
.button-078:after {
  border-radius: 20px;
}

.button-078:before {
  background-image: linear-gradient(92.83deg, #ff7426 0, #f93a13 100%);
  content: "";
  display: block;
  height: 90%;
  left: 5%;
  overflow: hidden;
  position: absolute;
  top: 5%;
  width: 90%;
  z-index: -2;
}

.button-078:after {
  background-color: initial;
  background-image: linear-gradient(#541a0f 0, #0c0d0d 100%);
  bottom: 4px;
  content: "";
  display: block;
  left: 4px;
  overflow: hidden;
  position: absolute;
  right: 4px;
  top: 4px;
  transition: all 100ms ease-out;
  z-index: -1;
}

.button-078:hover:not(:disabled):before {
  background: linear-gradient(92.83deg, rgb(255, 116, 38) 0%, rgb(249, 58, 19) 100%);
}

.button-078:hover:not(:disabled):after {
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  transition-timing-function: ease-in;
  opacity: 0;
}

.button-078:active:not(:disabled) {
  color: #ccc;
}

.button-078:active:not(:disabled):before {
  background-image: linear-gradient(0deg, rgba(0, 0, 0, .2), rgba(0, 0, 0, .2)), linear-gradient(92.83deg, #ff7426 0, #f93a13 100%);
}

.button-078:active:not(:disabled):after {
  background-image: linear-gradient(#541a0f 0, #0c0d0d 100%);
  bottom: 4px;
  left: 4px;
  right: 4px;
  top: 4px;
}

.button-078:disabled {
  cursor: default;
  opacity: .24;
}