#help-menu {
    position: absolute;
    top: 210px;
    left: calc(720px/2 - 540px/2 + 30px);
    width: 550px;
    height: 610px;
    background: #FFFFFF;
    z-index: 1250;
    border: 2px solid rgb(40, 19, 90);
    background-image: none;
    padding: 5px;
    display: none;   /* set this to flex to make visible */
    flex-direction: column;
    align-content: center;
    background-image: url('../../img/bg-01-lighter.jpg');
    
}

.help-head { 
    font-family: Papyrus, Milonga, Cantora One, Alike Angular, fantasy; 
    text-align: center;
    margin-top: 0px; margin-bottom: 0px;
    border: none;
}

#help-menu p {
    font-size:20px;
    font-family: 'Acme', sans-serif;
    margin-top: 19px;
}
.bold { font-weight: bold; }

#close-help {
    position: absolute;
    right: 5px;
    height: 30px;
    width: 30px; 
}
