#all-spells-UI {
  position: absolute;

  height: fit-content;
  background: #fffef9;
  z-index: 1200;
  border: 2px solid black;
  display: none;
  padding-left: 6px;
  z-index: 1140;
}

.all-spells {
  top: 110px;
  width: 400px;
  left: calc(720px/2 - 540px/2 + 30px);
}

.all-spells-mobile {
  top: 50px;
  width: 270px;
  left: 10px;
}
.spells-by-class h3 {
  font-size: 16pt;
  margin-top: 8px;
  margin-bottom: 4px;
}
.all-spells-list {
  margin-left: 0;
  padding-left: 0;
}

.spell-name {
  list-style-type: none;
  margin-left: 0;
  padding-left: 0;
  cursor: pointer;
  font-size: 14pt;
}