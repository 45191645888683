@import url('https://fonts.googleapis.com/css2?family=Germania+One&family=Gotu&family=Karma&family=Saira+Condensed&display=swap');


.flexigrid {
    outline: 1px solid;
    width: fit-content;
}


[id*='-grid-sm'], [id='labels'] {
    grid-template-rows: repeat(3,6px) repeat(6, 8px) repeat(12, 7px) repeat(14, 7.7px) repeat(10, 7px) repeat(6, 7px) repeat(4, 8px) repeat(18,11px) repeat(5,11px) repeat(10,8px) repeat(6,11.5px) repeat(12,10px) repeat(6,7.5px)
}

.cl-cell {
    border: 0px solid grey;
    background-color: transparent;
}

.cl-in {
    font-size: 11px;
    width: 96%;
    background-color:transparent;
    text-align: left;
    
}

.cl-in[id*='-name-in']  {
    font-size: 14px;
    text-align: center;
}

.cl-in[id*='-myClass']  {
    font-weight: bold;
    font-size: 12px;
}

.cl-in[id*='-myLevel'], .cl-in[id*='-myMov'] { font-size: 13px; }

.cl-in[id*='-myRace'], .cl-in[id*='-myAlign'] { font-size: 12px; padding-left: 1px; }

.cl-in[id*='-myDeity'] { text-align: right }

.cl-in[id*='-myLevel'], .cl-in[id*='-myHei'], .cl-in[id*='-myWei'], .cl-in[id*='-myMov'], .cl-in[id*='-myNatt']
{
    text-align: center;
}

.cl-in[id*='myStr'], .cl-in[id*='myInt'] , .cl-in[id*='myWis'] , .cl-in[id*='myDex'] , .cl-in[id*='myCon'] , .cl-in[id*='myCha'] {
    text-align: center;
}

[id*='Bon-in'] {

}

.cl-in[id*='-myToHit'],
.cl-in[id*='-myAC'] {
    text-align: center;
    font-size: 16px;
    color: royalblue;
    font-weight: bold;
}

.cl-in[id*='-myAC'] {
    color: black;
}

.cl-in[id*='Txt'] { font-size: 10px;
    font-family: 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    pointer-events: none;              
  }
  
.cl-in[id*='ACTxt' ] { font-size: 12px; }

.cl-in[id*='myHPbase'] { font-size: 14px; text-align: center; color:black;  }
.cl-in[id*='myHPcurr'] { font-size: 16px; text-align: center; color:rgb(170, 28, 170)  }
.cl-in[id*='myHPnote'] { text-align: center; color:#5b9f0a; font-family: 'Saira Condensed', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; }

.cl-in[id*='myWeap'], .cl-in[id*='myWth'], .cl-in[id*='myWdb'], .cl-in[id*='myWdmg'] { text-align: center; font-size: 11px; font-family: 'Saira Condensed', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; }
.cl-in[id*='myWeap'] { font-size: 11px; text-align: left; }
.cl-in[id*='myWth'] { color: royalblue; font-size: 13px; }
.cl-in[id*='myWdb'] { color: rgb(104, 0, 6); font-size: 13px; }
.cl-in[id*='myWdmg'] { font-size: 11px; }
.cl-in[id*='myWth'], .cl-in[id*='myWdb'], .cl-in[id*='myWdmg'] { font-weight: bold; }

.cl-in[id*='myAb'] { text-align: center; }
.cl-in[id*='SavTxt'] {  margin-left: 1px; font-family: 'Saira Condensed', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; }
.cl-in[id*='mySbon'] { font-size: 9px; }
.cl-in[id*='mySav'] { font-size: 11px; color: royalblue; }
.cl-in[id*='mySbon6'] { font-size: 10px; font-family: 'Saira Condensed', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; color: royalblue; }
.cl-in[id*='myFol'] { font-size: 11px; font-family: 'Saira Condensed', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; }
.cl-in[id*='mySp'] { font-size: 10px; font-family: 'Saira Condensed', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; }
.cl-in[id*='myTsp'] { font-size: 9px; text-align: center; }
.cl-in[id*='myNsp'] { font-size: 9px; color: rgb(170, 28, 170) }
.cl-in[id*='myMag'], .cl-in[id*='myPot'] { font-size: 9px; }
.cl-in[id*='myOth'] { font-size: 11px; font-family: 'Saira Condensed', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; }
.cl-in[id*='myQpo'] { font-size: 9px; text-align: center; }
.cl-charlong-textbox { z-index: 500; font-family: 'Arial'; font-size: 9px; margin:0; padding:0; border: none; width: 96%; height: 96%; line-height: 9px; text-align: left }
.cl-in[id*='myXP'] { font-size: 11px }
.cl-in[id*='myXPbonus'], .cl-in[id*='myXPnext'] { font-size: 9px }


.cl-load-but { width: 14px; font-size: 9px; margin:0; padding: 1px; text-align:right; }

.character-dropdown-sm {
    display: inline-block;
        position: absolute;
        top: 1px; left:120px;
        margin-right: 0px;
        margin-left: 0px;
        border: none;
        border-radius: 4px; 
        text-align: center;
        vertical-align: middle;
        font-family: 'Acme', sans-serif;
        background-color: transparent;
        z-index: 120;
    }

.characters-menu-sm {
    position: absolute;
    top: 0px;
    left: 0px;
    
    list-style-type: none;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0px;
    
    border: 0px solid grey;
    width: 300px;
    z-index: 1100;
    }
    
.characters-menu-sm > li {
margin: 0;
background-color: white;
}

.characters-menu-sm > li:hover {
background-color: lightgray;
}

.characters-menu-sm > li > button {
width: 100%;
height: 100%;
text-align: left;
background:rgb(255, 246, 222);
color: inherit;
border: none;
padding-top: 5px;
padding-left: 12px;
margin: 0;
font: inherit;
font-size: 12pt;
cursor: pointer;

z-index: 1001;
}