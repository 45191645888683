@font-face {
  font-family: 'Papyrus';
  src: url('https://advancedgamemaster.com/fonts/PAPYRUS.woff2') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lucida Sans';
  src: url('https://advancedgamemaster.com/fonts/LSANS.woff2') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Consolas';
  src: url('https://advancedgamemaster.com/fonts/consola.woff2') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gill Sans MT';
  src: url('https://advancedgamemaster.com/fonts/GIL_SANS_MT.woff2') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}