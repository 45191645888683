/* ---------- Grid Definitions for original charsheet design ---------- */

.grid-container {
  display: grid;
  grid-template-columns: 36px 36px 36px 36px 36px 36px 36px 36px 36px 36px 36px 36px 36px 36px 36px 36px 36px 36px 36px 36px;
  grid-template-rows: 52px 32px 32px 32px 32px 32px 32px 28px 32px 32px 32px 32px 32px 28px 32px 32px 32px 32px 32px 24px 24px 24px 24px 28px 28px 28px 28px 28px 28px 28px 28px 28px;
  grid-gap: 0px;
  padding: 0px;
  background-color: rgba(255, 255, 255, 0.75);    }

#char-form-01 {
    border: 0px blue;
    width: fit-content;
    /* margin-top: 10px; */
  }

  [id*='text-'] {font-size:16px;}

.grid-container > div {
  /* background-color: rgba(255, 255, 255, 0.35); */
  text-align: center;
  vertical-align: middle;
  display: flex;            
  align-items: center;      
  justify-content: center;
  padding: 0px;    }

/* -----------  box definitions  -------------*/  
#box-name { grid-area: 1/1/2/21; border: 2px solid; }
    #charphoto { grid-area: 1 / 1 / 2 / 3; }
    #charname { grid-area: 1 / 2 / 2 / 21; font-family: Papyrus, Milonga, Cantora One, Alike Angular, fantasy; font-size: 30px; font-weight: bold; z-index: 999 }
    #savebut  { grid-area: 1 / 17 / 1 / 19; }
    #loadbut  { grid-area: 1 / 19 / 1 / 21; }

#box-stats  { grid-area: 2/1/8/6; border-bottom: 1px solid black; }
    #text-str { grid-area: 2/1/3/3; border-left: 2px solid; border-bottom: 1px solid; border-right: 1px solid; }
    #text-int { grid-area: 3/1/3/3; border-left: 2px solid; border-bottom: 1px solid; border-right: 1px solid;}
    #text-wis { grid-area: 4/1/4/3; border-left: 2px solid; border-bottom: 1px solid; border-right: 1px solid;}
    #text-dex { grid-area: 5/1/5/3; border-left: 2px solid; border-bottom: 1px solid; border-right: 1px solid;}
    #text-con { grid-area: 6/1/6/3; border-left: 2px solid; border-bottom: 1px solid; border-right: 1px solid;}
    #text-cha { grid-area: 7/1/7/3; border-left: 2px solid; border-bottom: 2px solid; border-right: 1px solid;}
    #stat-str { grid-area: 2/3/2/5; border-bottom: 1px solid; border-right: 1px solid;}
    #stat-int { grid-area: 3/3/3/5; border-bottom: 1px solid; border-right: 1px solid;}
    #stat-wis { grid-area: 4/3/4/5; border-bottom: 1px solid; border-right: 1px solid;}
    #stat-dex { grid-area: 5/3/5/5; border-bottom: 1px solid; border-right: 1px solid;}
    #stat-con { grid-area: 6/3/6/5; border-bottom: 1px solid; border-right: 1px solid;}
    #stat-cha { grid-area: 7/3/7/5; border-bottom: 2px solid; border-right: 1px solid;}
    #bons-str { grid-area: 2/5/2/6; border-bottom: 1px solid; border-right: 1px solid;}
    #bons-int { grid-area: 3/5/3/6; border-bottom: 1px solid; border-right: 1px solid;}
    #bons-wis { grid-area: 4/5/4/6; border-bottom: 1px solid; border-right: 1px solid;}
    #bons-dex { grid-area: 5/5/5/6; border-bottom: 1px solid; border-right: 1px solid;}
    #bons-con { grid-area: 6/5/6/6; border-bottom: 1px solid; border-right: 1px solid;}
    #bons-cha { grid-area: 7/5/7/6; border-bottom: 2px solid; border-right: 1px solid;}

#box-mov  { grid-area: 2/6/4/8; border-bottom: 1px solid black; border-right: 2px solid black; }
    #text-mov { grid-area: 2/6/2/8; border-right: 2px solid black; }
    #stat-mov { grid-area: 3/6/3/8; border-right: 2px solid black; border-bottom: 1px solid black; }

#box-hei  { grid-area: 4/6/6/8; border-bottom: 1px solid black; border-right: 2px solid black; }
    #text-hei { grid-area: 4/6/4/8; border-right: 2px solid black; }
    #stat-hei { grid-area: 5/6/5/8; border-right: 2px solid black; border-bottom: 1px solid black; }

#box-wei  { grid-area: 6/6/8/8; border-bottom: 2px solid black; border-right: 2px solid black; }
    #text-wei { grid-area: 6/6/6/8; border-right: 2px solid black; }
    #stat-wei { grid-area: 7/6/7/8; border-bottom: 2px solid black; border-right: 2px solid black; }

#box-class{ grid-area: 2/8/4/14;  border-right: 2px solid;}
    #text-class { grid-area: 2/8/3/10; justify-content:left; padding-left: 10px; }
    #text-level { grid-area: 3/8/4/10; justify-content:left; padding-left: 10px; border-bottom: 1px solid black;}
    #stat-class { grid-area: 2/10/3/14; border-right: 2px solid black;}
    #stat-level { grid-area: 3/10/4/12; border-bottom: 1px solid black;}
    #text-gold { grid-area: 4/12/5/14; border-right: 2px solid black; }
    #stat-gold { grid-area: 5/12/6/14; border-bottom: 1px solid black; border-right: 2px solid black; z-index: 101; }

#box-xp   { grid-area: 4/8/6/14; border-bottom: 1px solid; border-right: 2px solid;}
    #text-xp { grid-area: 4/8/5/9; padding-left: 6px; }
    #stat-xp { grid-area: 4/9/5/12; border-right: 1px solid; }
    #char0-grid-full-myXP #myXP, #myXPNext {text-align: left;}
    #stat-xpbonus { grid-area:3/12/4/14; border-bottom: 1px solid; }
    #myXPbonus { color: darkblue; }
    #text-xpNext { grid-area: 5/8/6/10; }
    #stat-xpNext { grid-area: 5/10/6/12; border-bottom: 1px solid; border-right: 1px solid; text-align: left; }

#box-race { grid-area: 6/8/8/14; border-bottom: 2px solid; border-right: 2px solid; align-items: left; }
    #text-race { grid-area: 6/8/7/10; justify-content:left; padding-left: 10px; }
    #text-align { grid-area: 7/8/8/10; justify-content:left; padding-left: 10px; border-bottom: 2px solid; }
    #stat-race { grid-area: 6/9/7/12; padding-left: 10px; }
    #stat-align { grid-area: 7/10/8/11; border-bottom: 2px solid; }
    #text-deity { grid-area: 6/12/7/14; justify-content: left; }
    #stat-deity { grid-area: 7/11/8/14; border-bottom: 2px solid; border-right: 2px solid; }

#box-hp   { grid-area: 2/14/4/21; border-bottom: 1px solid; border-right: 2px solid;}
    #text-hp { grid-area: 2/14/3/15; }
    #stat-hpcurr { grid-area: 2/15/4/18; border-bottom: 1px solid;  }
    #stat-hpbase { grid-area: 3/14/4/15; border-bottom: 1px solid;  }
    #stat-hpnote { grid-area: 2/18/4/21; line-height: 40px;  border-right: 2px solid; border-bottom: 1px solid; }

#box-ac   { grid-area: 4/14/6/18; border-bottom: 1px solid; border-right: 1px solid;}
    #text-ac { grid-area: 4/14/5/15;}
    #text-ac2 { grid-area: 5/14/6/15; border-bottom: 1px solid;}
    #stat-ac { grid-area: 4/15/6/18; border-bottom: 1px solid; border-right: 1px solid; }

#box-rear   { grid-area: 4/18/5/21; border-bottom: 1px solid; border-right: 2px solid;}
    #text-rear { grid-area: 4/18/5/19; border-bottom: 1px solid; }
    #stat-rear { grid-area: 4/19/5/21; border-bottom: 1px solid; border-right: 2px solid; }

#box-side   { grid-area: 5/18/6/21; border-bottom: 1px solid; border-right: 2px solid;}
    #text-side { grid-area: 5/18/6/19;  border-bottom: 1px solid;}
    #stat-side { grid-area: 5/19/6/21; border-bottom: 1px solid; border-right: 2px solid; }

#box-hit   { grid-area: 6/14/8/18; border-bottom: 2px solid; border-right: 1px solid;}
    #text-hit { grid-area: 6/14/7/15; text-align: left;}
    #text-hit2 { grid-area: 7/14/8/15; text-align: left; border-bottom: 2px solid; }
    #stat-hit { grid-area: 6/15/8/18; border-bottom: 2px solid; border-right: 1px solid; } 

#box-natt   { grid-area: 6/18/8/21; border-bottom: 2px solid; border-right: 2px solid;}
    #text-natt { grid-area: 6/18/7/19;}
    #text-natt2 { grid-area: 7/18/8/19; border-bottom: 2px solid;}
    #stat-natt { grid-area: 6/19/8/21; border-bottom: 2px solid; border-right: 2px solid; }

#box-armor   { grid-area: 8/1/14/8; border-bottom: 2px solid; border-right: 2px solid; border-left: 2px solid; }
    #stat-baseac { grid-area: 8/1/9/2; border-bottom: 1px solid; border-right: 1px solid; border-left: 2px solid; }
    #stat-ab1 { grid-area: 9/1/10/2; border-bottom: 1px solid; border-right: 1px solid; border-left: 2px solid; }
    #stat-ab2 { grid-area: 10/1/11/2; border-bottom: 1px solid; border-right: 1px solid; border-left: 2px solid; }
    #stat-ab3 { grid-area: 11/1/12/2; border-bottom: 1px solid; border-right: 1px solid; border-left: 2px solid; }
    #stat-ab4 { grid-area: 12/1/13/2; border-bottom: 1px solid; border-right: 1px solid; border-left: 2px solid; }
    #stat-ab5 { grid-area: 13/1/14/2; border-bottom: 1px solid; border-right: 1px solid; border-left: 2px solid; }

    #text-armor { grid-area: 8/2/9/8; border-bottom: 1px solid; border-right: 2px solid black; background-color: #ffedb4;}
    #stat-arm1 { grid-area: 9/2/10/8; border-bottom: 1px solid; border-right: 2px solid;  }
    #stat-arm2 { grid-area: 10/2/11/8; border-bottom: 1px solid; border-right: 2px solid; }
    #stat-arm3 { grid-area: 11/2/12/8; border-bottom: 1px solid; border-right: 2px solid; }
    #stat-arm4 { grid-area: 12/2/13/8; border-bottom: 1px solid; border-right: 2px solid; }
    #stat-arm5 { grid-area: 13/2/14/8; border-bottom: 1px solid; border-right: 2px solid; }

#box-weapon   { grid-area: 8/8/14/21; border-bottom: 2px solid black; border-right: 2px solid;}
    #text-weapon { grid-area: 8/8/9/14; border-bottom: 1px solid; border-right: 1px solid; background-color: #ffcc99; }
    #text-wth { grid-area: 8/14/9/16; border-bottom: 1px solid; border-right: 1px solid; background-color: #ffcc99;}
    #text-wdb { grid-area: 8/16/9/18; border-bottom: 1px solid; border-right: 1px solid; background-color: #ffcc99;}
    #text-wdmg { grid-area: 8/18/9/21; border-bottom: 1px solid; border-right: 2px solid black; background-color: #ffcc99; }
    #stat-weap1 { grid-area: 9/8/10/14; border-bottom: 1px solid; border-right: 1px solid; }
    #stat-weap2 { grid-area: 10/8/11/14; border-bottom: 1px solid; border-right: 1px solid; }
    #stat-weap3 { grid-area: 11/8/12/14; border-bottom: 1px solid; border-right: 1px solid; }
    #stat-weap4 { grid-area: 12/8/13/14; border-bottom: 1px solid; border-right: 1px solid; }
    #stat-weap5 { grid-area: 13/8/14/14; border-bottom: 1px solid; border-right: 1px solid; }
    #stat-wth1 { grid-area: 9/14/10/16; border-bottom: 1px solid; border-right: 1px solid; }
    #stat-wth2 { grid-area: 10/14/11/16; border-bottom: 1px solid; border-right: 1px solid; }
    #stat-wth3 { grid-area: 11/14/12/16; border-bottom: 1px solid; border-right: 1px solid; }
    #stat-wth4 { grid-area: 12/14/13/16; border-bottom: 1px solid; border-right: 1px solid; }
    #stat-wth5 { grid-area: 13/14/14/16; border-bottom: 1px solid; border-right: 1px solid; }
    #stat-wdb1 { grid-area: 9/16/10/18; border-bottom: 1px solid; border-right: 1px solid; }
    #stat-wdb2 { grid-area: 10/16/11/18; border-bottom: 1px solid; border-right: 1px solid; }
    #stat-wdb3 { grid-area: 11/16/12/18; border-bottom: 1px solid; border-right: 1px solid; }
    #stat-wdb4 { grid-area: 12/16/13/18; border-bottom: 1px solid; border-right: 1px solid; }
    #stat-wdb5 { grid-area: 13/16/14/18; border-bottom: 1px solid; border-right: 1px solid; }
    #stat-wdmg1 { grid-area: 9/18/10/21; border-bottom: 1px solid; border-right: 1px solid; }
    #stat-wdmg2 { grid-area: 10/18/11/21; border-bottom: 1px solid; border-right: 1px solid; }
    #stat-wdmg3 { grid-area: 11/18/12/21; border-bottom: 1px solid; border-right: 1px solid; }
    #stat-wdmg4 { grid-area: 12/18/13/21; border-bottom: 1px solid; border-right: 1px solid; }
    #stat-wdmg5 { grid-area: 13/18/14/21; border-bottom: 1px solid; border-right: 1px solid; }
    
#box-pot   { grid-area: 14/1/24/8; border: 2px solid black; border-top: 0px; }
    #text-pot { grid-area: 14/1/15/8; border: 2px solid black; border-top: none; background-color: #c1ce96;}
    #stat-pot1 { grid-area: 15/1/16/7; border-bottom: 1px solid black; border-right: 1px solid black;}
    #stat-pot2 { grid-area: 16/1/17/7; border-bottom: 1px solid black; border-right: 1px solid black;}
    #stat-pot3 { grid-area: 17/1/18/7; border-bottom: 1px solid black; border-right: 1px solid black;}
    #stat-pot4 { grid-area: 18/1/19/7; border-bottom: 1px solid black; border-right: 1px solid black;}
    #stat-pot5 { grid-area: 19/1/20/7; border-bottom: 1px solid black; border-right: 1px solid black;}
    #stat-pot6 { grid-area: 20/1/21/7; border-bottom: 1px solid black; border-right: 1px solid black;}

    #text-fol { grid-area: 21/1/22/8; border: 2px solid black; border-top: 1px solid black; background-color: #ccaaff;}
    #stat-fol1 { grid-area: 22/1/23/8; border-bottom: 1px solid black;  border-right: 1px solid black; font-family:'Gill Sans', sans-serif }
    #stat-fol2 { grid-area: 23/1/24/8; border-bottom: 1px solid black;  border-right: 1px solid black; font-family:'Gill Sans', sans-serif }

    #stat-qpo1 { grid-area: 15/7/16/8; border-bottom: 1px solid black; }
    #stat-qpo2 { grid-area: 16/7/17/8; border-bottom: 1px solid black;}
    #stat-qpo3 { grid-area: 17/7/18/8; border-bottom: 1px solid black;}
    #stat-qpo4 { grid-area: 18/7/19/8; border-bottom: 1px solid black;}
    #stat-qpo5 { grid-area: 19/7/20/8; border-bottom: 1px solid black;}
    #stat-qpo6 { grid-area: 20/7/21/8; border-bottom: 1px solid black;}
    #stat-qpo7 { grid-area: 21/7/22/8; border-bottom: 1px solid black;}
    #stat-qpo8 { grid-area: 22/7/23/8; border-bottom: 1px solid black;}
    #stat-qpo9 { grid-area: 23/7/24/8; border-bottom: 1px solid black;}

#box-mag   { grid-area: 14/8/24/14; border-bottom: 2px solid black; border-right: 2px solid black;}
    #text-mag { grid-area: 14/8/15/14; border-bottom: 2px solid black; border-bottom: 2px solid black; border-right: 2px solid black; background-color: #a0bb44;}
    #stat-mag1 { grid-area: 15/8/16/14; border-bottom: 1px solid black; border-right: 1px solid black; }
    #stat-mag2 { grid-area: 16/8/17/14; border-bottom: 1px solid black; border-right: 1px solid black; }
    #stat-mag3 { grid-area: 17/8/18/14; border-bottom: 1px solid black; border-right: 1px solid black; }
    #stat-mag4 { grid-area: 18/8/19/14; border-bottom: 1px solid black; border-right: 1px solid black; }
    #stat-mag5 { grid-area: 19/8/20/14; border-bottom: 1px solid black; border-right: 1px solid black; }
    #text-rabi { grid-area: 20/12/21/14; border-bottom: 2px solid black; border-right: 2px solid black; border-top: 1px solid black; border-left: 2px solid black; background-color: #ccaaff; font-size: 10pt; justify-content: center;  }
    #stat-rabi { grid-area: 20/8/24/14; border-bottom: 1px solid black; border-right: 1px solid black; border-top: 1px solid black; }
    #stat-rabi1 { grid-area: 21/8/22/11; border-bottom: 1px solid black; border-right: 1px solid black;}
    #stat-rabi2 { grid-area: 22/8/23/11; border-bottom: 1px solid black; border-right: 1px solid black;}
    #stat-rabi3 { grid-area: 23/8/24/11; border-bottom: 1px solid black; border-right: 1px solid black;}

#box-oth   { grid-area: 14/14/24/21; z-index: 100; border-bottom: 2px solid black; border-right: 2px solid black; }
    #text-oth { grid-area: 14/14/15/21; border-bottom: 2px solid black; border-right: 2px solid black; background-color: #c1ce96; z-index: 101; }
    .div-item { margin-left: 0px; padding: 5px; border-bottom: 1px solid black; z-index: 101;  }
    .div-item-rt { border-right: 2px solid black; }
    #stat-oth1 { grid-area: 15/14/16/21; }
    #stat-oth2 { grid-area: 16/14/17/21; }
    #stat-oth3 { grid-area: 17/14/18/21; }
    #stat-oth4 { grid-area: 18/14/19/21; }
    #stat-oth5 { grid-area: 19/14/20/21; }

#box-sav {} /*nothing for now */
    #text-sav  { grid-area: 20/14/21/21; border-bottom: 2px solid black; border-right: 2px solid black; border-top: 1px solid black; background-color: #ccaaff; z-index: 101; }
    #text-sav1 { grid-area: 21/14/22/15; border-bottom: 1px solid black; justify-content: left; padding-left: 2px; z-index: 101; }
    #stat-sbon1 { grid-area: 21/15/22/16; border-bottom: 1px solid black; z-index: 101;}
    #stat-sav1 { grid-area: 21/16/22/17; border-bottom: 1px solid black; border-right: 1px solid black; z-index: 101; }
    #text-sav2 { grid-area: 22/14/23/15; border-bottom: 1px solid black; justify-content: left; padding-left: 2px; z-index: 101;}
    #stat-sbon2 { grid-area: 22/15/23/16; border-bottom: 1px solid black; z-index: 101;} 
    #stat-sav2 { grid-area: 22/16/23/17; border-bottom: 1px solid black; border-right: 1px solid black;z-index: 101;}
    #text-sav3 { grid-area: 23/14/24/15; border-bottom: 1px solid black; justify-content: left; padding-left: 2px;z-index: 101;}
    #stat-sbon3 { grid-area: 23/15/24/16; border-bottom: 1px solid black; z-index: 101;}
    #stat-sav3 { grid-area: 23/16/24/17; border-bottom: 1px solid black; border-right: 1px solid black;z-index: 101;}

    #text-sav4 { grid-area: 21/17/22/19; border-bottom: 1px solid black; justify-content: left; padding-left: 2px;z-index: 101;}
    #stat-sbon4 { grid-area: 21/19/22/20; border-bottom: 1px solid black; z-index: 101;}
    #stat-sav4 { grid-area: 21/20/22/21; border-bottom: 1px solid black; border-right: 1px solid black;z-index: 101;}

    #text-sav5 { grid-area: 22/17/23/19; border-bottom: 1px solid black; justify-content: left; padding-left: 2px; z-index: 101;}
    #stat-sbon5 { grid-area: 22/19/23/20; border-bottom: 1px solid black; z-index: 101; }
    #stat-sav5 { grid-area: 22/20/23/21; border-bottom: 1px solid black; border-right: 1px solid black; z-index: 101;}

    #text-sav6 { grid-area: 23/17/24/18; border-bottom: 1px solid black; justify-content: left; padding-left: 2px; z-index: 101;}
    #stat-sbon6 { grid-area: 23/18/24/21; border-bottom: 1px solid black; z-index: 101; }
    /* not used this line #stat-sav6 { grid-area: 23/20/24/21; border-bottom: 1px solid black; border-right: 1px solid black;}   */

#box-spells {grid-area: 24/1/33/21; border-bottom: 2px solid black; border-right: 2px solid black; border-left: 2px solid black;}
#box-spell-head { grid-area: 24/1/25/21; border-bottom: 2px solid black; border-right: 2px solid black; border-left: 2px solid black; background-color: #c5d3fd;}
.spell-edit-button { border: none; background-color: transparent; width: 100%; height: 100%; font: inherit; }
    #text-sp1  { grid-area: 24/1/25/3; border-bottom: 1px solid black; border-right: 1px solid black;}
    #stat-tsp1 { grid-area: 24/3/25/4; border-bottom: 1px solid black; border-right: 1px solid black; }
    #stat-nsp1 { grid-area: 24/4/25/5; border-bottom: 1px solid black; border-right: 1px solid black;}
    #text-sp2  { grid-area: 24/5/25/7; border-bottom: 1px solid black; border-right: 1px solid black;}
    #stat-tsp2 { grid-area: 24/7/25/8; border-bottom: 1px solid black; border-right: 1px solid black;}
    #stat-nsp2 { grid-area: 24/8/25/9; border-bottom: 1px solid black; border-right: 1px solid black;}
    #text-sp3  { grid-area: 24/9/25/11; border-bottom: 1px solid black; border-right: 1px solid black;}
    #stat-tsp3 { grid-area: 24/11/25/12; border-bottom: 1px solid black; border-right: 1px solid black;}
    #stat-nsp3 { grid-area: 24/12/25/13; border-bottom: 1px solid black; border-right: 1px solid black;}
    #text-sp4  { grid-area: 24/13/25/15; border-bottom: 1px solid black; border-right: 1px solid black;}
    #stat-tsp4 { grid-area: 24/15/25/16; border-bottom: 1px solid black; border-right: 1px solid black;}
    #stat-nsp4 { grid-area: 24/16/25/17; border-bottom: 1px solid black; border-right: 1px solid black;}
    #text-sp5  { grid-area: 24/17/25/19; border-bottom: 1px solid black; border-right: 1px solid black;}
    #stat-tsp5 { grid-area: 24/19/25/20; border-bottom: 1px solid black; border-right: 1px solid black;}
    #stat-nsp5 { grid-area: 24/20/25/21; border-bottom: 1px solid black; border-right: 1px solid black;}
    #stat-sp11 { grid-area: 25/1/26/5; border-bottom: 1px solid black; border-right: 1px solid black;}
    #stat-sp12 { grid-area: 26/1/27/5; border-bottom: 1px solid black; border-right: 1px solid black;}
    #stat-sp13 { grid-area: 27/1/28/5; border-bottom: 1px solid black; border-right: 1px solid black;}
    #stat-sp14 { grid-area: 28/1/29/5; border-bottom: 1px solid black; border-right: 1px solid black;}
    #stat-sp15 { grid-area: 29/1/30/5; border-bottom: 1px solid black; border-right: 1px solid black;}
    #stat-sp16 { grid-area: 30/1/31/5; border-bottom: 1px solid black; border-right: 1px solid black;}
    #stat-sp17 { grid-area: 31/1/32/5; border-bottom: 1px solid black; border-right: 1px solid black;}
    #stat-sp18 { grid-area: 32/1/33/5; border-bottom: 1px solid black; border-right: 1px solid black;}
    #stat-sp19 { grid-area: 33/1/34/5; border-bottom: 1px solid black; border-right: 1px solid black;}
    #stat-sp21 { grid-area: 25/5/26/9; border-bottom: 1px solid black; border-right: 1px solid black;}
    #stat-sp22 { grid-area: 26/5/27/9; border-bottom: 1px solid black; border-right: 1px solid black;}
    #stat-sp23 { grid-area: 27/5/28/9; border-bottom: 1px solid black; border-right: 1px solid black;}
    #stat-sp24 { grid-area: 28/5/29/9; border-bottom: 1px solid black; border-right: 1px solid black;}
    #stat-sp25 { grid-area: 29/5/30/9; border-bottom: 1px solid black; border-right: 1px solid black;}
    #stat-sp26 { grid-area: 30/5/31/9; border-bottom: 1px solid black; border-right: 1px solid black;}
    #stat-sp27 { grid-area: 31/5/32/9; border-bottom: 1px solid black; border-right: 1px solid black;}
    #stat-sp28 { grid-area: 32/5/33/9; border-bottom: 1px solid black; border-right: 1px solid black;}
    #stat-sp31 { grid-area: 25/9/26/13; border-bottom: 1px solid black; border-right: 1px solid black;}
    #stat-sp32 { grid-area: 26/9/27/13; border-bottom: 1px solid black; border-right: 1px solid black;}
    #stat-sp33 { grid-area: 27/9/28/13; border-bottom: 1px solid black; border-right: 1px solid black;}
    #stat-sp34 { grid-area: 28/9/29/13; border-bottom: 1px solid black; border-right: 1px solid black;}
    #stat-sp35 { grid-area: 29/9/30/13; border-bottom: 1px solid black; border-right: 1px solid black;}
    #stat-sp36 { grid-area: 30/9/31/13; border-bottom: 1px solid black; border-right: 1px solid black;}
    #stat-sp37 { grid-area: 31/9/32/13; border-bottom: 1px solid black; border-right: 1px solid black;}
    #stat-sp38 { grid-area: 32/9/33/13; border-bottom: 1px solid black; border-right: 1px solid black;}
    #stat-sp41 { grid-area: 25/13/26/17; border-bottom: 1px solid black; border-right: 1px solid black;}
    #stat-sp42 { grid-area: 26/13/27/17; border-bottom: 1px solid black; border-right: 1px solid black;}
    #stat-sp43 { grid-area: 27/13/28/17; border-bottom: 1px solid black; border-right: 1px solid black;}
    #stat-sp44 { grid-area: 28/13/29/17; border-bottom: 1px solid black; border-right: 1px solid black;}
    #stat-sp45 { grid-area: 29/13/30/17; border-bottom: 1px solid black; border-right: 1px solid black;}
    #stat-sp46 { grid-area: 30/13/31/17; border-bottom: 1px solid black; border-right: 1px solid black;}
    #stat-sp47 { grid-area: 31/13/32/17; border-bottom: 1px solid black; border-right: 1px solid black;}
    #stat-sp48 { grid-area: 32/13/33/17; border-bottom: 1px solid black; border-right: 1px solid black;}
    #stat-sp51 { grid-area: 25/17/26/21; border-bottom: 1px solid black; border-right: 1px solid black;}
    #stat-sp52 { grid-area: 26/17/27/21; border-bottom: 1px solid black; border-right: 1px solid black;}
    #stat-sp53 { grid-area: 27/17/28/21; border-bottom: 1px solid black; border-right: 1px solid black;}
    #stat-sp54 { grid-area: 28/17/29/21; border-bottom: 1px solid black; border-right: 1px solid black;}

#box-abi { grid-area: 29/17/34/21; border-left: 3px solid black; border: 1px solid black; border-top: 1px solid black; }
    #text-abi { grid-area: 29/17/30/21; border-bottom: 1px solid black; margin-left: 1px; margin-top: 1px; margin-right: 2px; background-color: #ccaaff;}
    #stat-abi { grid-area: 30/17/34/21; border-bottom: 1px solid black; }

/* ---------- Element Definitions ---------- */    

input[type=text] {
  width: 96%;
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  border: none;
  line-height: 24px;
  spellcheck: "false";
}    

input   {  width:40px;
text-align:center;    }

table   {   padding: 0px; margin:0px;
 border: 0px; border-collapse: collapse;    }
td      {   padding: 0px; margin:0px;
 border: 0px; border-collapse: collapse;    }
tr      {   padding: 0px; margin:0px;
 border: 0px; border-collapse: collapse;    }

textarea {  resize: none; }

p {
margin-bottom: 10px; margin-top:22px; font-size: 10pt;
}

h2      {   font-family:Georgia;
 margin-bottom: 0px;    
}


/* ---------- Class Definitions ---------- */

.indent{ margin-left:20px; }
.hidden { visibility:hidden; }   

.loadsave-button { font-size:10pt; width:50px; text-align:center; padding:0px; color: blue; }

.text-label { font-size: 16px; vertical-align: center; text-align: center; }
.text-sm { font-size: 11px; }
.text-hot {color: #ff00aa; }
.stat-value { font-size: 16px; color:royalblue ; background-color: transparent; }
.stat-val-lg { font-size: 14pt; color:royalblue ; background-color: transparent; width: 90%; }
.stat-val-sm { font-size: 11pt; color:royalblue ; background-color: transparent; width: 100%; margin: 0px; }
.stat-val-sm-hot { font-size: 11pt; color:#ff00aa ; background-color: transparent; }
.stat-val-sm-blk { font-size: 11pt; color:black ; background-color: transparent; }
.stat-val-xs { font-size: 9pt; color:royalblue ; background-color: transparent; }
.stat-val-wrap {padding: 0; font-size: 10pt; color:royalblue; background-color: transparent; }
.stat-bonus { font-size: 10px; color: brown; background-color: transparent; }

#myHPcurr {color: #ff00aa; font-size:14pt; }
#myHPnote {color: #5b9f0a; }
#myFol1, #myFol2 { text-align: left; font-size: 14px; }
#myRabi {width: 200px; height:96%; border: none; background-color: transparent; }
#myAbi {width: 140px; height:96%; border: none; background-color: transparent; font-size: 9pt;}

#savebut, #loadbut {
  margin: 0px;
  padding: 0px;
  background-color: transparent;
}

.loadsave-button2 { 
    width: 100px; 
    font-family: 'Acme', sans-serif;
    font-size: 16px;
    z-index: 1000;
    text-align: center;
    color: rgba(0, 0, 0, 0.895);
    border: none;
    display: inline-block;
    padding-left: auto;
    padding-right: auto;
    padding-top: 4px;
    padding-bottom: 4px;
    margin-right: 4px;
    margin-left: 0px;
    border: 1px black solid;
    border-radius: 4px; 
    text-align: center;
    vertical-align: middle;
    /* font-family: 'Caveat Brush', cursive; */
    background-color: rgba(217,211,161,0.51);
    }

/* ---------- By ID (specific element) ---------- */



