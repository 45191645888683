.flexigrid {
    display: grid;
    grid-gap: 0px;
  }

.flexicell {
    display: flex;
    align-items: center;
    justify-content: center;
    margin:0px;
    padding:0px;
    border: none;
  }
  
.flexiinput {
    width: 96%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    box-sizing:border-box;
    border: none;
    z-index: 100;
  }

  .flexibox {
    outline: 1px solid black;
    pointer-events: none; /* Allow clicks to pass through */
  }