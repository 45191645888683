.rolldie {
    width: 110px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: center;
    column-gap: 100px;
    row-gap: 16px;
    flex-wrap: wrap
}

.rolldie-img {
    height: 60px;
}

.rolldie-break {
    flex-basis: 100%;
    height: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.rolldie-qty {
  font-size: 20px;
  padding: 0px;
  -moz-appearance: textfield;  /* hide buttons on firefox */
}

.rolldie-rolls {
    color:rgba(0, 0, 255, 0.825);
    font-size: 24px;
    font-family: 'Saira Condensed', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    height: 18px;
    padding-top: 1px;
    padding-bottom: 22px;
}

.rolldie-stats {
    width: 100px;
    height: 120px;
    display: grid;
    grid-template-columns: 50px 50px;
    grid-template-rows: repeat(4, 30px);
    justify-content:center;
}

.rolldie-stat-text, .rolldie-stat-number {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 14px;
    text-align: right; 
}

.rolldie-stat-number {
    text-align: center;
}

.rolldie-best {
  font-size: 16px;
}

.rolldie-Total {
    font-size: 18px;
    font-weight: bold; 
}
.rolldie-updown {
    border-radius: 28px;
    height: 28px; width: 28px;
}

/* fancy button formatting (button29) */
.rolldie-updown {
  align-items: center;
  appearance: none;
  background-image: radial-gradient(100% 100% at 100% 0, #5adaff 0, #5468ff 100%);
  border: 0;
  /* box-shadow: rgba(45, 35, 66, .4) 0 2px 4px,rgba(45, 35, 66, .3) 0 7px 13px -3px,rgba(58, 65, 111, .5) 0 -3px 0 inset; */
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-family: "JetBrains Mono",monospace;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow .15s,transform .15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow,transform;
  font-size: 18px;
}

.rolldie-updown:focus {
  box-shadow: #3c4fe0 0 0 0 1.5px inset, rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
}

.rolldie-updown:hover {
  box-shadow: rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
  transform: translateY(-2px);
}

.rolldie-updown:active {
  box-shadow: #3c4fe0 0 3px 7px inset;
  transform: translateY(2px);
}