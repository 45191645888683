#navBarMobile {
/*
    width: 718px;
    display: flex;
    align-content: center;
    align-items: bottom;
    padding-bottom: 0px;
    margin-bottom: 0px;
    padding-top: 0px;
    border: 2px greenyellow;
    justify-content: space-between;
    */
    z-index: 1100; 

    position: fixed;
    top: 0;
    left: 0;

}

#navBarMobile .navbaritem {

    min-width: 32px;
    padding-right: 2px;
}


#navBarMobile #nav-menu-characters {
    width: 72px;
    min-width: 30px;
    padding-left: 0px;
}

#navBarMobile #nav-menu-rules {
    width: 36px;
    min-width: 30px;
    /* font-size: 8pt; */
}

#navBarMobile .spells-menu
{
  margin-left: -34px;
}

#navBarMobile #nav-menu-roll {
    width: 54px;
    min-width: 30px;
    /* font-size: 8pt; */
}


#navBarSpacer-mobile {
    border: 0px solid blue;
    min-width: 6px;
    width: 4px;
}

#corporate {

    display: inline;
    margin-right: -10px;
    padding-top: 1px;
    padding-bottom: -2px;
    font-family: Papyrus, Milonga, Cantora One, Alike Angular, Amarante, fantasy; 
    font-size: 1.24em;
    font-style: normal; 
    font-variant: normal; 
    font-weight: 900; 
}

#navBarMobileButtonsArea {
    display: inline-flex;
    padding-left: 0px;
    padding-bottom: 4px;
    margin: 0px;
}

#navBarMobileButtonsArea .nav-menu-button {
    font-size: 10pt;
    margin-right: 0px;
    margin-left: 0px;
    height: 36px;
}

#navBarMobileButtonsArea .navbaritem button {
    padding-inline: 2px;
}

#navBarMobileButtonsArea .navbaritem {
    margin-left: 0px;
    margin-right: 2px;
}



@media print
{    
    .no-print, .no-print *
    {
        display: none !important;
    }
}

@import url('https://fonts.googleapis.com/css2?family=Alike+Angular&family=Milonga&display=swap');