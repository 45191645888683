#charname-mobile {
  width: 288px;
  top: 42px;
  position:fixed;
  background-color: rgba(255, 255, 255, 0.75);
  height: 50px;
  border: 1px solid black;
  margin-right: 8px;
  display: flex;

  align-items: center;
}

#charnameName-mobile {

  
    padding-left: 4px;

    font-size: 20px;
    text-align: left;
    font-family: Papyrus, Milonga, Cantora One, Alike Angular, fantasy;
    font-weight: bold;
    z-index: 999;
    color: black;

  }

#charPhoto-mobile {
  max-height: 50px;
  z-index: 901;
  position: absolute;
  top: 0px;
  right: 0px;
  overflow-y: hidden;
  overflow-x: hidden;
  width: 73px;
}

#charPhoto-mobile img {
  display: block;
  overflow-y: hidden;
}