#editable-link-div {
    /* background-color: rgba(255, 255, 255, 0.35); */
    text-align: center;
    vertical-align: middle;
    display: flex;            
    align-items: center;      
    justify-content: center;
    padding: 0px;
    width: 150px;
    height: 28px;
    }

.editable-div {
    grid-column: 1;
    grid-row: 1;
    padding: 0px;
    margin: 0px;
    max-width: 100%;
}

.editable-link {
    /* width: 138px; */
    height: 22px;
    border: none;
    padding: 0px;
    margin: 0px;
}

.editable-div button {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
}

.editable-input {
    color:rgb(10, 88, 0) !important;
    display: none;
}
