#NavBar {

    width: 718px;
    display: flex;
    align-content: center;
    align-items: bottom;
    padding-bottom: 0px;
    margin-bottom: 0px;
    padding-top: 0px;
    border: 2px greenyellow;
    justify-content: space-between; 

}

#corporate {

    display: inline;
    margin-right: -10px;
    padding-top: 1px;
    padding-bottom: -2px;
    font-family: Papyrus, Milonga, Cantora One, Alike Angular, Amarante, fantasy; 
    font-size: 1.24em;
    font-style: normal; 
    font-variant: normal; 
    font-weight: 900; 
}

#navBarButtonsArea {
    display: inline-flex;
    padding-left: 10px;
    margin: 0px; 
}



@media print
{    
    .no-print, .no-print *
    {
        display: none !important;
    }
}

@import url('https://fonts.googleapis.com/css2?family=Alike+Angular&family=Milonga&display=swap');