@import url('https://fonts.googleapis.com/css2?family=Acme&Caveat+Brush&family=Indie+Flower&family=Patrick+Hand+SC&display=swap');

.navbaritem {
display: inline-block;
    /* padding-left: 9.5pt;
    padding-right: 9.5pt;
    padding-top: 4px;
    padding-bottom: 3.5px; */
    margin-right: 0px;
    margin-left: 8px;
    border: 1px black solid;
    border-radius: 4px; 
    text-align: center;
    vertical-align: middle;
    /* font-family: 'Caveat Brush', cursive; */
    font-family: 'Acme', sans-serif;
    font-size: 18px;
    background-color: #d9c3a181;
}

.nav-menu-button {
    font-family: 'Acme', sans-serif;
    font-size: 16px;
    z-index: 1000;
    text-align: center;
    color: rgba(0, 0, 0, 0.895);
    border: none;
    background-color: transparent;
    height: 30px; 
    margin-right: 9.5px;
    margin-left: 9.5px; 
    }


.menu {
    position: absolute;
  
    list-style-type: none;
    margin-left: auto;
    margin-right: auto;
    margin-top: 6px;
    padding-left: 0px;
  
    border: 2px solid grey;
    border-radius: 6px; 
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    
    width: 300px;
    z-index: 1150;

    background:rgb(255, 246, 222);

  }

  .menu > li {
    margin: 4px;
    background:rgb(255, 246, 222);
    border-radius:2px;
  }
  
  .menu > li:hover {
    background-color: lightgray;
  }
  
  .menu > li > button {
    width: 100%;
    height: 100%;
    text-align: left;
    background:rgb(255, 246, 222);
    color: inherit;
    border: none;
    border-radius: 2px;
    padding-top: 8px;
    padding-left: 12px;
    margin: 0;
    font: inherit;
    font-size: 16pt;
    cursor: pointer;

    z-index: 1001;
  }

  /* this is not working?? */
  #nav-menu-characters {
    flex-shrink: 2;
  }

.character-menu { margin-left: -2px; width: 260px;}
.rules-menu { margin-left: -10px; }
.spells-menu { margin-left: -10px; width: 200px;}
.settings-menu { margin-left: -106px; width: 205px; }

.character-menu > li > button { padding-top: 2.5px; }

#nav-settings-button { display: flex; align-items: center; }
#settings-text, #gear-icon-div { display: inline; }
#gear-icon { width: 18px; height: 18px; display: inline; padding-top: 2px; }