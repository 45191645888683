#songPlayerDiv {
  display: none;
  justify-content: left;
  margin: 1px;
  height: 50px;
  width: 300px;
  z-index: 2000;
  position: absolute;
  top: 42px;
}

#songPlayerNext {
  background-color: #faf1e2;
  border-radius: 10px;
  border-width: 1px;
  width: 66px;
  padding: 2px;
  flex-shrink: 0;
}

#nextSongTitle {
  font-size: 8pt;
}

#reactPlayer {
  background: none;
  border-radius: 5px;
  width: 222px;
  flex-shrink: 0;
}

#downloadPatcher {
  background-color: #faf1e2; 
  border-radius: 15px;
  height: 80%;
  width: 40px;
  position: relative;
  left: -48px;
  top: 2px;
  color: #faf1e2;
  z-index:1500;
  flex-shrink: 0;
}

audio::-webkit-media-controls-panel {
  background-color: #faf1e2;
}


audio::-webkit-media-controls-current-time-display,
audio::-webkit-media-controls-time-remaining-display
{
  font-size: 9pt;
}

audio::-webkit-media-controls-time-remaining-display
{
  display: none;
}

audio::-webkit-media-controls-more
{
  display: none;
}