body2    {  background-image:url("../../img/charsheet-port.jpg");
           background-repeat:no-repeat;
           background-attachment:fixed;
           background-position:top left;
           margin-left:20px;
           background-size: 756px 1024px;
           color:black;
           font-size: 12pt;
           font-family:  Helvetica;    }

