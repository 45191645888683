#roll-dice {
    display: none;
    width: fit-content;
    background-color: rgb(255, 244, 209);
    flex-direction: column;
    align-items: flex-start;
    align-content: flex-start;
    column-gap: 100px;
    row-gap: 16px;
    flex-wrap: wrap;
    position: absolute;
    top: 50px;
    left: 20px;
    z-index: 1100;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    border: grey 2px solid;
    border-radius: 10px;
}

.dice-mobile {
    transform: scale(0.45) translate(-460px,-300px);
}

#roll-dice .dice-mobile {
    
}

#rolls-box {
    position: absolute;
    top: 282px;
    left: 2px;
    border: 2px solid blue;
    height: 40px;
    width: 99%;
}

#rolls-box-title {
    position: absolute;
    top: 259px;
    left: 304px;
    font-size: 14px;
    font-weight: 700;
    font-family: sans-serif;
    width: fit-content;
    padding: 5px;
    padding-bottom: 2px;
    background-color: rgb(255, 244, 209);
    color: rgba(0, 0, 255, 0.825);
    border-radius: 3px;
    border: rgba(0, 0, 255, 0.825) 2px solid;
    border-bottom: none;
    z-index:1111;
}

#roll-dice-row-1 {
    width: 660px;
    height: 60px;
    border: none;
    position: relative;
}

#roll-dice-row-2 {
    display: flex;
    width: fit-content;
}

#roll-all-button {
    height: fit-content;
    color: black;
    position: absolute;
    left: 0px;
}

.roll-row1-txt {
    font-size: 16px;
    position: absolute;
    font-family: 'Saira Condensed', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    top: 18px;
}

#roll-add-this { left: 150px; }
#roll-add-this-value { left: 205px; }
#roll-avg-txt { left: 267px; }
#roll-avg {left: 297px; }
#roll-grand-txt { left: 335px; }
#roll-grand-tot { left: 460px; top: 6px; }

#roll-add-this-value {
    font-size: 16px;
    position: absolute;
    top: 10px;
    height: 35px;
    width: 40px;
    font-family: Arial, Helvetica, sans-serif;
}

.roll-grand-tot {
    font-size: 22px;
    position: absolute;
    font-family: Arial, Helvetica, sans-serif;
    top: 17.5px;
}

#roll-grand-tot {
    border-radius: 10px;
    border: black solid 1px;
    background-color: white;
    padding: 10px;
    width: 40px;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
}


#reset-all-button {
    height: fit-content;
    color: black;
    position: absolute;
    right: 0px;
    padding-left: 22px;
    padding-right: 22px;
}



/* CSS button 87*/
.button-87 {
  margin: 10px;
  padding: 12px 30px 12px 30px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  border-radius: 10px;
  display: block;
  border: 0px;
  font-size: 14px;
  font-weight: 700;
  box-shadow: 0px 0px 14px -7px #f09819;
  background-image: linear-gradient(3deg, #fcbd11 0%, #fdd568  51%, #fcbd11  100%);
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-87:hover {
  background-position: right center;
  /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.button-87:active {
  transform: scale(0.95);
}

.greenie {
    background-image: linear-gradient(2deg, rgb(125, 187, 58) 0%, rgb(179,228,126)  51%, rgb(125, 187, 58)  100%);
}
