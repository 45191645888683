#five-wide {
    display: flex;
    flex-direction: row;
    font-size: 6.6px;
    font-family: 'Saira Condensed', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    background-color: rgba(255, 255, 255, .75);
}

#char-form-05 {
    background-color: rgba(255, 255, 255, .75);
}

.lab-grid { background-color: transparent; }   /* labels */
.lab-cell { background-color: transparent; }
.lab-in   { background-color: transparent; }

.lab-in {
    pointer-events: none;
    width: 96%;
    font-family: 'Saira Condensed', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}