@import url('https://fonts.googleapis.com/css2?family=Germania+One&family=Gotu&family=Karma&family=Saira+Condensed&display=swap');

#charsheet-scroll {
    margin-top: 92px;
    height: 600px;
    position: fixed;
    top: 0;
    overflow-y: auto;
    overflow-x:hidden;
    border: 1px black solid;
    width: 288px;
}

#charsheet-scroll::-webkit-scrollbar, 
#charsheet-scroll::-webkit-scrollbar-thumb,
#charsheet-scroll::-webkit-scrollbar-track {
    width: 1px;
  }


.charsheetMobile { 
    width: min-content;
}

.flexigrid {
    outline: 1px solid;
    width: fit-content;
}

.cf-grid {
    background-color: rgba(255, 255, 255, 0.75);
}

/* ---------- BASIC GRID SETUP -----------*/
[id*='Mobile-grid-full'] {
    grid-template-rows: repeat(1, 0px)   /* empty-not used */
                        repeat(2, 30px)  /* character info */
                        repeat(4, 26px)  /* character info */
                        repeat(3, 32px)  /* stats */
                        repeat(1, 24px)  /* Padding */
                        repeat(4, 27px)  /* AC and HP rows */
                        repeat(6, 29px)  /* Weapons */
                        repeat(4, 26px)  /* Saving Throws */
                        repeat(6, 25px)  /* Armor */
                        repeat(26, 25px)
                        repeat(4, 0px)   /* empty for now */
                        repeat(30, 24px) 
}

/* ---------- DIV BACKGROUNDS -----------*/
#charMobile-div {
    background-color: rgba(255, 255, 255, .75);
}

.flexibox[id*='cf-box'] {
    z-index: 525;
}

#charMobile-grid-full-StrTxt,
#charMobile-grid-full-IntTxt,
#charMobile-grid-full-WisTxt,
#charMobile-grid-full-DexTxt,
#charMobile-grid-full-ConTxt,
#charMobile-grid-full-ChaTxt {
    background-color: rgba(255, 203, 199, 0.78);
    z-index: 355;
    padding-bottom: 2px;
}

#charMobile-grid-full-StatBuffer {
    pointer-events: none;
    background-color: rgba(100, 173, 255, 0.48);
    margin-top: 1px;
}

#charMobile-grid-full-StatBuffer input {
    color: black;
    font-family: "Saira Condensed";
}

#charMobile-grid-full-ArmTxt {
    background-color: rgba(255, 237, 180, 1);
    margin-top: 1px;
}
#charMobile-grid-full-myAC {
    margin-left: -4pt;
}

#charMobile-grid-full-myStr input,
#charMobile-grid-full-myInt input,
#charMobile-grid-full-myWis input,
#charMobile-grid-full-myDex input,
#charMobile-grid-full-myCon input,
#charMobile-grid-full-myCha input {
    font-size: 16pt;
    color: black;
}


#charMobile-grid-full-myClass input,
#charMobile-grid-full-myLevel input
{
    font-size: 18pt;
}

#charMobile-grid-full-myHPbase input,
#charMobile-grid-full-myHPcurr input,
#charMobile-grid-full-myMov input {
    font-size: 20pt;
}

#charMobile-grid-full-myMov {
    margin-top: -2pt;
}

#charMobile-grid-full-myHPcurr {
    margin-top: 4px;

}
#charMobile-grid-full-WeaTxt,
#charMobile-grid-full-WthTxt,
#charMobile-grid-full-WdbTxt,
#charMobile-grid-full-WdgTxt
{
    background-color: #ffcc99;
    margin-top: 1px;
}

#charMobile-grid-full-WthTxt input
    {
    padding-top: 2px;
    margin-bottom: -0px;
}

#charMobile-grid-full-mySav1 input,
#charMobile-grid-full-mySav2 input,
#charMobile-grid-full-mySav3 input,
#charMobile-grid-full-mySav4 input,
#charMobile-grid-full-mySav5 input {
    font-size: 19pt;
    font-family:'Courier New', Courier, monospace
}

#charMobile-grid-full-mySbon1 input,
#charMobile-grid-full-mySbon2 input,
#charMobile-grid-full-mySbon3 input,
#charMobile-grid-full-mySbon4 input,
#charMobile-grid-full-mySbon5 input {
    font-size: 16pt;
    font-family:'Courier New', Courier, monospace
}


#charMobile-grid-full-PotTxt,
#charMobile-grid-full-MagTxt,
#charMobile-grid-full-OthTxt,
#charMobile-grid-full-AbiTxt
{
    background-color:#a9c54b;
    margin-top: 1px;
}

.flexibox[id*='boxSpt'],
.flexibox[id*='boxSpq']
{
    background-color: #c5d3fd;
    margin-top: 1px;
}

#charMobile-grid-full-FolTxt, 
#charMobile-grid-full-RacTxt,
#charMobile-grid-full-SaveTxt
{
    background-color: #ccaaff;
    margin-top: 1px;
}

/* ---------- INPUT CELL DEFINITIONS -----------*/
.cf-cell {
    border: 0px solid rgb(129, 78, 78);
    background-color: transparent; 
    z-index: 600;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.cf-in {
    font-size: 16px;
    width: 96%;
    background-color:transparent;
    color: royalblue;
    
}

/* ---------- Character Name -----------*/
#charMobile-grid-full-name .cf-in[id*='Txt'] {
    text-align: center;
    font-size: 16px;
    color: black;
    font-family: 'Saira Condensed', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    pointer-events: none;
    z-index: 650; 
}

#charMobile-grid-full-name .cf-in[id*='name']  {
    font-size: 20px;
    text-align: left;
    font-family: Papyrus, Milonga, Cantora One, Alike Angular, fantasy; font-weight: bold; z-index: 999;
    color: black;
}



#charMobile-grid-full-char-image-mobile-img {
    object-fit: fill;
    height: 106%;
    width: 100%;
    margin: 0px;
}

#charMobile-grid-full-char-image-mobile {
    border-image-width: 0px;
    align-items:flex-start;
}

#charMobile-grid-full-gTotImg { 
    margin-top: 4px;
    width: 40px !important;
    margin-left: 4px;
}

#charMobile-grid-full-gTotImg-img { 
    width: 120%;
}

/* MOVE THIS TO CHARSHEETFULL.css */
#char0-grid-full-gTotImg-img {
    width: 60%;
}

/* ---------- Class Section -----------*/
#charMobile-grid-full-myClass .cf-in[id*='myClass'] {
    text-align: left;
}

#charMobile-grid-full-myDeity .cf-in[id*='myDeity'] { font-size: 16px; font-family: 'Saira Condensed' }

[id*='Bon'] {
    font-size: 11px;
    color:rgb(104, 0, 6);
}


#charMobile-grid-full-myToHit .cf-in[id*='myToHit'] {
    font-size: 18px;
    color: royalblue;
}

div[id^="charMobile-grid-full-"]
 .cf-in[id*='myRace'], 
 .cf-in[id*='myAli'],
 .cf-in[id*='myHei'],
 .cf-in[id*='myWei']
{
    text-align: left;
    padding-left: 0px;
}

.cf-in[id*='ClassTxt'],
.cf-in[id*='LvlTxt'],
.cf-in[id*='RaceTxt'],
.cf-in[id*='AliTxt'],
.cf-in[id*='HeiTxt'],
.cf-in[id*='WeiTxt']
{
    text-align: left;
    padding-left: 6px;
}

/* overrides for stuff in charsheetfull.css */
div[id^="charMobile-grid-full-"] 
.cf-in[id*='XPTxt'], 
.cf-in[id*='myXP'],
.cf-in[id*='DeiTxt'] {
    text-align: center;
    padding-left: 0px;
}

#charMobile-grid-full-myAC .cf-in[id*='myAC'] { font-size: 28px; }

[id*="charMobile-grid-full-myArm"] input {
    font-size: 10pt;
}


/* .cf-in[id*='myXPnext'] { font-size: 11px; text-align: left; } */

  
.cf-in[id*='ACTxt' ] { font-size: 18px; }

.cf-in[id*='myHPbase'] { text-align: center; }
.cf-in[id*='myHPcurr'] { font-size: 18px; text-align: center; color:#ff00aa  }
.cf-in[id*='myHPnote'] { color:#5b9f0a; font-family: 'Saira Condensed', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; }

.cf-in[id*='myWth'] { color: rgb(153, 62, 1); font-size: 20px; font-weight: bold; }
.cf-in[id*='myWdb'] { color: rgb(2, 97, 61); font-size: 20px; font-weight: bold; } 
.cf-in[id*='myWeap'] { font-size: 15px;  }

.cf-in[id*='myOth'] { font-size: 16px; font-family: 'Saira Condensed', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; }
.cf-in[id*='myFol'] { font-size: 14px; font-family: 'Saira Condensed', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; text-align: left }

.cf-in[id*='mySav'] { font-size: 15px; color: royalblue; text-align: center; }
.cf-in[id*='SavTxt'] {  font-size: 12px; margin-left: 1px; font-family: 'Saira Condensed', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; text-align: left;}
.cf-in[id*='mySbon'] { font-size: 12px; }

.cf-charlong-textbox { z-index: 500; color: royalblue; font-size: 12px; margin:0; padding:0; border: none; width: 96%; height: 96%; line-height: 12px; text-align: left; background-color: transparent; }

.editable-link[id*='mySp'] { font-size: 14px; text-align: left; }
.editable-input[id*='mySp'] { font-size: 14px; text-align: center; padding: 3px; }
.cf-in[id*='myTsp'] { font-size: 14px; text-align: center; }
.cf-in[id*='myNsp'] { font-size: 14px; color: #ff00aa }

.cf-spellbutton {
    border: none;
    font-family: 'Saira Condensed';
    color: black;
}

/* 

.cf-in[id*='mySbon6'] { font-size: 10px; font-family: 'Saira Condensed', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; color: royalblue; } */
/* .cf-in[id*='myWeap'], .cf-in[id*='myWth'], .cf-in[id*='myWdb'], .cf-in[id*='myWdmg'] { text-align: center; font-size: 11px; font-family: 'Saira Condensed', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; }
.cf-in[id*='myWeap'] { font-size: 11px; text-align: left; }

.cf-in[id*='myWdb'] { color: rgb(104, 0, 6); font-size: 13px; }
.cf-in[id*='myWdmg'] { font-size: 11px; }
.cf-in[id*='myWth'], .cf-in[id*='myWdb'], .cf-in[id*='myWdmg'] { font-weight: bold; }
.cf-in[id*='myAb'] { text-align: center; }
.cf-in[id*='SavTxt'] {  font-size: 12px; margin-left: 1px; font-family: 'Saira Condensed', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; text-align: left;}
.cf-in[id*='myFol'] { font-size: 11px; font-family: 'Saira Condensed', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; }

.cf-in[id*='myTsp'] { font-size: 9px; text-align: center; }

.cf-in[id*='myMag'], .cf-in[id*='myPot'] { font-size: 9px; }
.cf-in[id*='myQpo'] { font-size: 9px; text-align: center; }

.cf-charlong-textbox { z-index: 500; font-family: 'Arial'; font-size: 10px; margin:0; padding:0; border: none; width: 96%; height: 96%; line-height: 9px; text-align: left }

.cf-in[id*='myXPbonus'], .cf-in[id*='myXPnext'] { font-size: 9px }
*/


#charMobile-grid-full-RacTxt { z-index: 650 }

#cf-boxWthTxt, #cf-boxWdpTxt,#cf-boxWdgTxt,
#cf-boxFolTxt, #cf-boxRacTxt, #cf-boxSavTxt,
#cf-boxSt1, #cf-boxSt2, #cf-boxSt2, #cf-boxSt3, #cf-boxSt4,
#cf-boxMoreTxt { z-index: 651 } /* was 651 */

#cf-boxArmTxt, #cf-boxWeaTxt, #cf-boxMagTxt, #cf-boxOthTxt, #cf-boxPotTxt { z-index: 599 } 

/* the extra thick borders */
#cf-boxStats,
#cf-boxStats2,
#cf-boxStats3 
{ outline: black solid 2px; }


/* ----------  LOAD BUTTONS ETC  ------------*/

.cf-load-but-mobile { 
    width: 60px;
    height: 30px; 
    font-family: 'Acme', sans-serif;
    font-size: 12px;
    z-index: 1000;
    text-align: center;
    color: rgba(0, 0, 0, 0.895);
    border: none;
    display: inline-block;
    padding-left: auto;
    padding-right: auto;
    padding-top: 4px;
    padding-bottom: 4px;
    margin-right: 1px;
    margin-left: 3px;
    margin-top: 50px;
    border: 1px black solid;
    border-radius: 4px; 
    text-align: center;
    vertical-align: middle;
    /* font-family: 'Caveat Brush', cursive; */
    background-color: rgba(207, 196, 93, 0.51);
    }

.character-dropdown-mobile {
    display: block;
        position: absolute;
        top:-36px; right:4px;
        background-color: transparent;
        margin-right: 0px;
        margin-left: 0px;
        border: none;
        border-radius: 4px; 
        text-align: center;
        vertical-align: middle;
        z-index: 720;
    }

.characters-menu-mobile {
    position: absolute;
    top: 60px;
    left: -40px;
    
    list-style-type: none;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0px;
    
    border: 0px solid grey;
    width: 124px;
    z-index: 1100;
    }
    
.characters-menu-mobile > li {
margin: 0;
background-color: white;
}

.characters-menu-mobile > li:hover {
background-color: lightgray;
}

.characters-menu-mobile > li > button {
width: 100%;
height: 100%;
text-align: left;
background:#fcf4e5;
color: inherit;
border: none;
padding-top: 5px;
padding-left: 12px;
margin: 0;
font-family: 'Acme', sans-serif;
font-size: 13pt;
cursor: pointer;

z-index: 1001;
}

div[id*="charMobile-grid-full-mySp"] {
    position: relative;
}

div[id*="charMobile-grid-full-mySp"] button[id*="mySp"] {
    position: absolute;
    top: 0px;
    left: 0px;
}

div[id*="charMobile-grid-full-mySp"] input[id*="mySp"] {
    position: absolute;
    top: 0px;
    left: 0px;
}

div[id^="charMobile-grid-full-myWeap"] input {
    width: 142px;
}

div[id^="charMobile-grid-full-myPot"], div[id^="charMobile-grid-full-myArm"]  input {
    width: 250px;
}

div[id^="charMobile-grid-full-myMag"] input {
    width: 284px;
}

#charMobile-grid-full-myAbi, #char0-grid-full-myAbi, #charMobile-grid-full-myRabi, #char0-grid-full-myRabi {
    height: 100% !important;
}

#charMobile-grid-full-mySbon7, #charMobile-grid-full-myAge { z-index: 1100; }
#charMobile-grid-full-mySbon7 input { font-size: 12pt; }
#charMobile-grid-full-myAge input { font-size: 10pt; color:rgba(0, 0, 0, 0.895) }
#charMobile-grid-full-AgeTxt input { text-align: right; }