.floatingwindow {
  position: absolute;
  top: 2.0%;
  left: 1.0%;
  /* transform: translate(-50%, -50%); */
  background-color: #ffffff;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 5px;
  max-width: 800px; /* Max width for desktop */
  width: 95%; /* 75% of screen width for mobile devices */
  height: fit-content; /* 75% of viewport height for mobile devices */
  z-index: 1100;
}

.floatingwindow h1 {
  margin: 0px;
  text-align: center;
  font-size: 18pt;
  color: #ee5206;
  font-family: 'Karma', Times, serif;

}

.floatingwindow button {
  position: absolute;
  right: 1%;
  font-weight: 500;
  min-width: 40px;
  width: 40px;
  flex-shrink: 0;
  min-height: 30px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  padding: 0px;
  border-width: 1px;
}

/* Add media query for mobile devices */
@media (max-width: 1450px) {
  .floatingwindow {
    width: fit-content; /* 75% of viewport width for mobile devices */
    /* 75% of viewport height for mobile devices */
  }
}

.floatingwindow p {
  font-size: 10pt;
  font-weight: bold;
  font-family: 'Times New Roman', Times, serif;
  margin-bottom: 5px;
  margin-top: 5px;
  text-align: center;
  color: #3a6e09
}